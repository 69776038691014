import { useTranslation } from 'react-i18next';

import Button from '@mui/material/Button';
import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import { useHostingSshInfo } from '@newfold/huapi-js';

import Copy from '~/components/Copy';
import InfoListItem from '~/components/InfoListItem';
import SsoLink from '~/components/SsoLink';
import useAccount from '~/hooks/useAccount';
import { useBrandInfo } from '~/hooks/useBrandInfo';
import useFeature from '~/hooks/useFeature';
import useHostingInfo from '~/hooks/useHostingInfo';
import useQueryFeature from '~/hooks/useQueryFeature';

const SshAccessInfo = () => {
  const { t } = useTranslation('site');

  type SshTypes = {
    ssh: {
      id: string;
      label: string;
      tooltipText: string;
      inlineCta: string;
      secondaryCta: string;
      secondayLabel: string;
    };
  };
  const { ssh } = t('quickConnect.items', { returnObjects: true }) as SshTypes;

  const { id: hostingId } = useAccount();

  const { isDisabled } = useHostingInfo();
  const [hasSites] = useFeature('sites');
  const [isDestiny] = useFeature('destiny');
  const [isCPanel] = useFeature('cpanel');
  const [enableDestiny] = useQueryFeature('destiny');
  const { brandFromJWT } = useBrandInfo();
  const isBlueHost = brandFromJWT.toLowerCase() === 'bluehost';

  const enableSSshInfo = !isDisabled && hasSites;
  const {
    isPending: sshLoading,
    data: sshData,
    isError: isSshError,
  } = useHostingSshInfo(hostingId, {
    query: {
      enabled: enableSSshInfo,
    },
  });

  if (!hasSites) {
    return null;
  }

  const sshKey = sshData?.data?.credential;
  return (
    // @ts-expect-error
    <InfoListItem
      key={ssh?.id}
      // @ts-expect-error
      title={ssh?.label}
      // @ts-expect-error
      tooltipText={ssh?.tooltipText}
      // @ts-expect-error
      inlineCTA={
        enableDestiny || isDestiny || (isCPanel && isBlueHost) ? (
          <Button variant="text" href={'/ssh-keys'} color="primary">
            {ssh?.inlineCta}
          </Button>
        ) : (
          <SsoLink
            variant="text"
            type={'hosting'}
            queryParams={{ app: 'sshkeys' }}
            data-testid="btn-advanced"
            aria-disabled
          >
            {ssh?.inlineCta}
          </SsoLink>
        )
      }
    >
      {sshLoading && (
        <Stack direction="row" spacing={1}>
          <Skeleton width={185} height={24} />
          <Skeleton width={64} height={24} />
        </Stack>
      )}
      {!isSshError && !sshLoading && sshKey && (
        <Copy disable={isDisabled}>
          <Typography
            sx={{ wordBreak: 'break-all' }}
            variant="body1"
            data-testid="ssh-credential"
          >
            {sshKey}
          </Typography>
        </Copy>
      )}
    </InfoListItem>
  );
};

export default SshAccessInfo;

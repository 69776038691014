import { useTranslation } from 'react-i18next';

import LockOpenOutlined from '@mui/icons-material/LockOpenOutlined';
import LockOutlined from '@mui/icons-material/LockOutlined';
import CardActionArea from '@mui/material/CardActionArea';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import useTheme from '@mui/material/styles/useTheme';
import Typography from '@mui/material/Typography';

import { HostingSitesV2200ItemsItem } from '@newfold/huapi-js/src/index.schemas';

import useHostingInfo from '~/hooks/useHostingInfo';
import useSitePath from '~/hooks/useSitePath';
import { getCanShowManageInTitle } from '~/utils/siteMeta';

interface SiteTitlePropOptions extends HostingSitesV2200ItemsItem {
  disabled: boolean;
}

const SiteTitle = ({
  name,
  id,
  pending_url,
  url,
  status,
  disabled,
}: SiteTitlePropOptions) => {
  const { t } = useTranslation('sites');
  const theme = useTheme();

  const { isDisabled } = useHostingInfo();
  const { siteRedirect } = useSitePath();

  const canShowManage = getCanShowManageInTitle({ status });
  const canShowAction = canShowManage;

  const activeUrl = pending_url || url;
  const hasSsl = activeUrl && activeUrl.startsWith('https://');

  const activeUrlProtocol = activeUrl ? `${activeUrl.split('//', 2)[0]}//` : '';
  const activeUrlAfterProtocol = activeUrl ? activeUrl.split('//', 2)[1] : '';

  return (
    <CardActionArea
      onClick={() => siteRedirect(id)}
      disabled={!canShowAction || isDisabled || disabled}
      disableRipple
    >
      <Grid container>
        <Grid item xs={12}>
          <Typography component="div" noWrap translate="no">
            {name ? name : t('siteWithoutName', { siteId: id })}
          </Typography>

          {/* Don't render URL if one doesn't exist */}
          {activeUrl && (
            <Stack direction="row" spacing={0.5}>
              {hasSsl ? (
                <LockOutlined
                  sx={{ mt: '2px' }} // nudge icon down to line up with text
                  color="success"
                  data-testid={`ssl-locked-icon-${id}`}
                />
              ) : (
                <LockOpenOutlined
                  sx={{ color: theme.palette.grey[500], mt: '2px' }}
                  data-testid={`ssl-unlocked-icon-${id}`}
                />
              )}
              <Typography
                variant="body2"
                noWrap
                data-testid={`site-${id}-url`}
                translate="no"
              >
                {/* @ts-expect-error */}
                <Typography
                  component="span"
                  variant="body2"
                  color={
                    hasSsl
                      ? theme.palette.success.main
                      : theme.palette.grey[500]
                  }
                  wordbreak="break-word"
                  data-testid={`site-${id}-url`}
                >
                  {activeUrlProtocol}
                </Typography>
                {activeUrlAfterProtocol}
              </Typography>
            </Stack>
          )}
        </Grid>
      </Grid>
    </CardActionArea>
  );
};

export default SiteTitle;

// import StorageIcon from '@mui/icons-material/Storage';
import { useQueryClient } from '@tanstack/react-query';
import { PropsWithChildren } from 'react';
import { useTranslation } from 'react-i18next';

import InfoIcon from '@mui/icons-material/Info';
import WarningIcon from '@mui/icons-material/Warning';
import Alert from '@mui/material/Alert';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Stack from '@mui/material/Stack';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';

import { getHostingSitesV2QueryKey, useSite } from '@newfold/huapi-js';
import {
  HostingSitesV2200ItemsItem,
  SiteInfoResponseModelV2,
} from '@newfold/huapi-js/src/index.schemas';

import { useAlert } from '~/components/Alerts/alertsStore';
import LoadingButtonWrapper from '~/components/LoadingButtonWrapper';
import SsoLink from '~/components/SsoLink';
import useAccount from '~/hooks/useAccount';
import useHostingInfo from '~/hooks/useHostingInfo';
import WordPressIcon from '~/scenes/Sites/assets/icons/WordPressIcon';
import { getSiteDisabled } from '~/utils/getSiteDisabled';

const installingStatuses = ['pending_create', 'create'];
const failedInstallingStatuses = ['failed_create'];
const configuringStatuses = ['pending_configure', 'configure'];
const failedConfigureStatus = ['failed_configure'];
const deletingStatuses = ['pending_delete', 'delete'];

export default function SiteCardActions({
  site,
}: PropsWithChildren<{
  site: HostingSitesV2200ItemsItem | SiteInfoResponseModelV2;
}>) {
  const { t } = useTranslation('sites');
  const { isDisabled: isHostingDisabled } = useHostingInfo();
  const { id: hostingId } = useAccount();
  const generateAlert = useAlert();

  const queryClient = useQueryClient();
  const queryKey = getHostingSitesV2QueryKey(hostingId);

  const { mutate: deleteSite, isPending: isPendingSiteDeletion } = useSite({
    mutation: {
      onSuccess: () => {
        generateAlert({
          severity: 'success',
          description: t('cloud.deleteSite.alertMessage.success', {
            siteName: site?.name,
          }),
          showCloseBtn: true,
        });
      },
      onError: () => {
        generateAlert({
          severity: 'error',
          description: t('cloud.deleteSite.alertMessage.error'),
          showCloseBtn: true,
        });
      },
      onSettled: () => {
        queryClient.invalidateQueries({ queryKey });
      },
    },
  });

  const handleSubmit = (event: any) => {
    deleteSite({ siteId: Number(site?.id!) });
  };

  const siteId = site?.id;
  const siteStatus = site?.status!;
  const isMigration = !!site?.migration_id;
  const isSiteDisabled = getSiteDisabled(siteStatus);

  const disableSSO = isHostingDisabled || !siteId || isSiteDisabled;

  // Migration Site Failed
  if (isMigration && failedInstallingStatuses.includes(siteStatus))
    return (
      <Stack direction="row" spacing={2}>
        <Alert
          severity="info"
          // @ts-ignore
          type="simple"
          icon={<CircularProgress size={20} />}
        >
          <Typography variant="body2">
            {t('disableSite.migratingTxt')}
          </Typography>
        </Alert>
        <LoadingButtonWrapper
          variant="outlined"
          color="error"
          loading={isPendingSiteDeletion}
          onClick={handleSubmit}
        >
          {t('cloud.deleteSite.btnTxt')}
        </LoadingButtonWrapper>
      </Stack>
    );

  // Migrating Site
  if (isMigration && installingStatuses.includes(siteStatus))
    return (
      <Stack spacing={2} alignItems={'start'}>
        <Alert
          severity="info"
          // @ts-ignore
          type="simple"
          icon={<CircularProgress size={20} />}
        >
          <Typography variant="body2">
            {t('disableSite.migratingTxt')}
          </Typography>
        </Alert>
      </Stack>
    );

  // Configure Site Failed
  if (failedConfigureStatus.includes(siteStatus))
    return (
      <Stack direction="row" spacing={2}>
        <Alert
          severity="warning"
          // @ts-ignore
          type="simple"
          icon={<WarningIcon fontSize="small" />}
        >
          <Stack spacing={1} direction="row" alignItems="center">
            <Typography variant="body2">
              {t('disableSite.configureFailed')}
            </Typography>
            <Tooltip
              title={t('disableSite.failedConfigureToolTip')}
              arrow
              placement="top-start"
            >
              {/* @ts-ignore */}
              <InfoIcon fontSize="small" />
            </Tooltip>
          </Stack>
        </Alert>
      </Stack>
    );

  // Configuring Site
  if (configuringStatuses.includes(siteStatus))
    return (
      <Stack spacing={2} alignItems={'start'}>
        <Alert
          severity="info"
          //@ts-ignore
          type="simple"
          icon={<CircularProgress size={20} />}
        >
          <Typography variant="body2">
            {t('disableSite.configuring')}
          </Typography>
        </Alert>
      </Stack>
    );

  // Deleting Site
  if (deletingStatuses.includes(siteStatus))
    return (
      <Stack spacing={2} alignItems={'start'}>
        <Alert
          severity="error"
          //@ts-ignore
          type="simple"
          icon={<CircularProgress size={20} color="error" />}
        >
          <Typography variant="body2" color={'secondary'}>
            {t('disableSite.deleting')}
          </Typography>
        </Alert>
      </Stack>
    );

  // Delete Site Failed
  if (siteStatus.includes('failed_delete'))
    return (
      <Stack direction="row" spacing={2}>
        <Alert
          severity="error"
          // @ts-ignore
          type="simple"
          icon={<WarningIcon fontSize="small" />}
        >
          <Stack spacing={1} direction="row" alignItems="center">
            <Typography variant="body2">
              {t('disableSite.deleteFailed')}
            </Typography>
            <Tooltip
              title={t('disableSite.failedDeleteToolTip')}
              arrow
              placement="top-start"
            >
              {/* @ts-ignore */}
              <InfoIcon fontSize="small" />
            </Tooltip>
          </Stack>
        </Alert>
        <LoadingButtonWrapper
          variant="outlined"
          color="error"
          loading={isPendingSiteDeletion}
          onClick={handleSubmit}
        >
          {t('cloud.deleteSite.btnTxt')}
        </LoadingButtonWrapper>
      </Stack>
    );

  // Create Site Failed
  if (failedInstallingStatuses.includes(siteStatus))
    return (
      <Stack direction="row" spacing={2}>
        <Alert
          severity="info"
          //@ts-ignore
          type="simple"
          icon={<CircularProgress size={20} />}
        >
          <Stack spacing={1} direction="row" alignItems="center">
            <Typography variant="body2">
              {t('disableSite.installingWordPress')}
            </Typography>
            <Tooltip
              title={t('disableSite.failedCreateToolTip')}
              arrow
              placement="top-start"
            >
              {/* @ts-ignore */}
              <InfoIcon fontSize="small" />
            </Tooltip>
          </Stack>
        </Alert>
        <LoadingButtonWrapper
          variant="outlined"
          color="error"
          loading={isPendingSiteDeletion}
          onClick={handleSubmit}
        >
          {t('cloud.deleteSite.btnTxt')}
        </LoadingButtonWrapper>
      </Stack>
    );

  // Creating Site
  if (siteStatus?.includes('create'))
    return (
      <Stack spacing={2} alignItems={'start'}>
        <Alert
          severity="info"
          //@ts-ignore
          type="simple"
          icon={<CircularProgress size={20} />}
        >
          <Typography variant="body2">
            {t('disableSite.installingWordPress')}
          </Typography>
        </Alert>
      </Stack>
    );

  // Active Site
  return (
    <Stack direction={'row'} spacing={1}>
      <Button
        sx={{ flex: { xs: 1, sm: 'auto' } }}
        variant="outlined"
        href={siteId ? `sites/${siteId}` : undefined}
        disabled={isHostingDisabled}
      >
        Manage
      </Button>

      {/* wordpress sso button */}
      <SsoLink
        type="site"
        variant="contained"
        disabled={disableSSO}
        id={siteId}
        // @ts-expect-error
        sx={{ flex: { xs: 1, sm: 'auto' } }}
        startIcon={<WordPressIcon color="#fff" />}
      >
        WP Admin
      </SsoLink>
    </Stack>
  );
}

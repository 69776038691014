import { closeSnackbar, enqueueSnackbar } from 'notistack';
import { createHook, createStore, defaults } from 'react-sweet-state';

defaults.devtools = true;

const Store = createStore({
  name: `Hosting MFE Alerts`,
  initialState: {
    alertList: [],
  },
  actions: {
    generateAlert: (alert) => () => {
      const { description, ...restProps } = alert;
      enqueueSnackbar({
        variant: 'alertTemplate',
        description,
        ...restProps,
      });
    },
    removeAlert: (id) => () => {
      closeSnackbar(id);
    },
  },
});

const useAlertStore = createHook(Store);

export default useAlertStore;

export const useAlert = () => {
  const [, { generateAlert }] = useAlertStore();
  return generateAlert;
};

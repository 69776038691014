import { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';

import { useSiteUpdate } from '@newfold/huapi-js';

import useAlerts from '~/components/Alerts/alertsStore';
import LoadingButtonWrapper from '~/components/LoadingButtonWrapper';
import { DOMAIN_PATTERNS } from '~/utils/validateDomain';

type RenameModalPropOptions = {
  showModal?: boolean;
  closeModal: VoidFunction;
  siteName?: string;
  siteId: number | string;
};

const RenameModal = ({
  showModal = false,
  siteName = '',
  closeModal,
  siteId,
}: RenameModalPropOptions) => {
  const { t } = useTranslation('site');
  const [, { generateAlert }] = useAlerts();

  const newSiteName = useRef<HTMLInputElement | undefined>();
  const [invalidInput, setInvalidInput] = useState(false);
  const [disableRenameButton, setDisableRenameButton] = useState(true);

  const { isPending, mutate: updateSite } = useSiteUpdate({
    mutation: {
      // TODO: Optimistic Update Opportunity
      onError: (error) => {
        generateAlert({
          severity: 'error',
          description: t('error.alertError'),
          showCloseBtn: true,
        });
      },
      onSuccess: async () => {
        generateAlert({
          severity: 'success',
          description: t('renameModal.alertSuccess'),
          showCloseBtn: true,
        });
        closeModal();
      },
    },
  });

  const handleChange = (e: any) => {
    e.preventDefault();

    const hasUnicode = DOMAIN_PATTERNS.UNICODE.test(e?.target?.value);
    setInvalidInput(hasUnicode);

    setDisableRenameButton(
      newSiteName?.current?.value === siteName ||
        newSiteName?.current?.value.length === 0 ||
        !newSiteName?.current?.value?.trim() ||
        hasUnicode,
    );
  };

  const handleCTA = (e: any) => {
    e.preventDefault();
    updateSite({
      siteId: Number(siteId),
      data: { title: newSiteName?.current?.value.trim() },
    });
  };

  const handleCancel = () => {
    setInvalidInput(false);
    closeModal();
  };

  return (
    <Dialog open={showModal} onClose={closeModal} fullWidth maxWidth="sm">
      <form onSubmit={(e) => handleCTA(e)}>
        <DialogTitle>{t('renameModal.title')}</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            id="name"
            label={t('renameModal.inputLabel')}
            defaultValue={siteName}
            inputRef={newSiteName}
            type="text"
            fullWidth
            onChange={(e) => handleChange(e)}
            helperText={invalidInput && t('renameModal.invalidInput')}
            error={invalidInput}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCancel}>
            {t('renameModal.closeBtnText')}
          </Button>
          <LoadingButtonWrapper
            loading={isPending}
            disabled={disableRenameButton}
            onClick={handleCTA}
            data-testid="rename-btn"
          >
            {t('renameModal.ctaBtnText')}
          </LoadingButtonWrapper>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default RenameModal;

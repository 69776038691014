import { useEffect, useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';

import useLocalStorage from './useLocalStorage';

const useQueryFeature = (requestedFeature: string) => {
  const [searchParams] = useSearchParams();

  type OptionsTypes = {
    [key: string]: string;
  };
  const options = {} as OptionsTypes;

  const featureParam = searchParams.get('feature');
  // This is so that checking multiple features can be done using a single
  // query parameter (e.g. ?feature=domains,ssls) instead of needing multiple
  const featureList = featureParam?.split(',') || [];

  const [featureFlags, setFeatureFlags] = useLocalStorage(
    'featureFlags', // local storage key
    featureList, // local storage default value
  );

  useEffect(() => {
    if (!!requestedFeature && featureList?.includes(requestedFeature)) {
      setFeatureFlags(featureList);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [featureParam]);

  const isFeatureEnabled = useMemo(
    () => featureFlags?.includes(requestedFeature),
    [featureFlags, requestedFeature],
  );

  //The rest of params are mapped to an "options" object
  for (const [key, value] of searchParams.entries()) {
    if (key !== 'feature') {
      options[key] = value;
    }
  }

  return [!!isFeatureEnabled, options] as [boolean, OptionsTypes];
};

export default useQueryFeature;

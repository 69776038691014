import { closeSnackbar } from 'notistack';
import { useLayoutEffect } from 'react';

import DeleteIcon from '@mui/icons-material/Delete';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import useLocalStorage from '~/hooks/useLocalStorage';

import { useAlert } from './alertsStore';

const QueryFeatureAlertBar = () => {
  const [featureFlags, , removeItem] = useLocalStorage('featureFlags', []);
  const generateAlert = useAlert();

  const clearFeatureFlags = () => {
    removeItem('featureFlags');
    closeSnackbar();
  };

  useLayoutEffect(() => {
    if (!!featureFlags && featureFlags?.length > 0) {
      generateAlert({
        severity: 'info',
        title: (
          <Stack direction="row" spacing={1}>
            <Typography>Query Feature Flags:</Typography>
            <DeleteIcon
              onClick={clearFeatureFlags}
              sx={{ cursor: 'pointer' }}
            />
          </Stack>
        ),
        description: (
          <Stack direction="row" spacing={1}>
            {featureFlags?.map((feature: string) => (
              <Typography key={feature}>"{feature}"</Typography>
            ))}
          </Stack>
        ),
        showCloseBtn: true,
        persist: true,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [featureFlags]);

  return <></>;
};
export default QueryFeatureAlertBar;

import { useMFEContext } from '~/components/MFEProvider';

interface BrandInfo {
  phoneNumberFormatted: string;
  phoneNumberHref: string;
  isDNSManagedInCpanel: boolean;
  domainName: string;
  path: string;
  contactLink: string;
  optimizeLink: string;
  supportLink: string;
  wpSolutionLearnMoreUrl: string;
  loggingInUsingExtClient: string;
  basicSSHCommands: string;
  commonSSHErrors: string;
  indexesLearnMoreUrl: string;
  redirectsLearnMoreUrl: string;
}

// TODO: ideally this is passed from host application
// or managed in a service or endpoint of its own
const brands = {
  default: {
    phoneNumberFormatted: '',
    phoneNumberHref: '',
    isDNSManagedInCpanel: false,
    domainName: '',
    path: '',
    contactLink: '',
    optimizeLink: '',
    supportLink: '',
    wpSolutionLearnMoreUrl: '',
    loggingInUsingExtClient: '',
    basicSSHCommands: '',
    commonSSHErrors: '',
    indexesLearnMoreUrl: '',
    redirectsLearnMoreUrl: '',
  } as BrandInfo,
  bluehost: {
    phoneNumberFormatted: '1-888-401-4678',
    phoneNumberHref: 'tel:+18884014678',
    isDNSManagedInCpanel: true,
    domainName: 'www.bluehost.com',
    path: 'Bluehost%20Cloud',
    contactLink: 'https://www.bluehost.com/contact',
    optimizeLink:
      'https://www.bluehost.com/help/article/optimize-hosting-reduce-disk-space',
    supportLink: 'https://www.bluehost.com/contact',
    wpSolutionLearnMoreUrl: 'https://www.bluehost.com',
    loggingInUsingExtClient:
      'https://www.bluehost.com/help/article/ssh-connections',
    basicSSHCommands: 'https://www.bluehost.com/help/article/ssh-commands',
    commonSSHErrors: 'https://www.bluehost.com/help/article/common-ssh-errors',
    indexesLearnMoreUrl:
      'https://www.bluehost.com/help/article/am-index-manager',
    redirectsLearnMoreUrl:
      'https://www.bluehost.com/help/article/redirect-a-domain',
  } as BrandInfo,
  hostgator: {
    phoneNumberFormatted: '1-866-964-2867',
    phoneNumberHref: 'tel:+18669642867',
    isDNSManagedInCpanel: true,
    supportLink: 'https://www.hostgator.com/contact',
    //domainName and path not implemented, since Hostgator does not yet support Atomic
    contactLink: 'https://www.hostgator.com/contact',
    optimizeLink:
      'https://www.hostgator.com/help/article/optimize-and-free-up-hosting-disk-space',
    // TODO: Need to confirm hostgator link
    loggingInUsingExtClient:
      'https://www.hostgator.com/help/article/ssh-keying-through-putty-on-windows-or-linux',
    // TODO: Need to confirm hostgator link
    basicSSHCommands: 'https://www.hostgator.com/help/article/shell-commands',
    // TODO: Need to confirm hostgator link
    commonSSHErrors: 'https://www.hostgator.com/help/article/common-ssh-errors',
    indexesLearnMoreUrl:
      'https://www.hostgator.com/help/article/am-index-manager ',
    // TODO: Need to confirm hostgator link
    redirectsLearnMoreUrl:
      'https://www.hostgator.com/help/article/redirect-a-domain',
  } as BrandInfo,
  vodien: {
    // TODO: update values from brand
    phoneNumberFormatted: '+65 6018 3544',
    phoneNumberHref: 'tel:+65%206018%203544',
    isDNSManagedInCpanel: false,
    supportLink: 'https://help.vodien.com',
    //domainName and path not implemented, since Hostgator does not yet support Atomic
    contactLink: 'https://www.vodien.com/contact',
    optimizeLink: '',
  } as BrandInfo,
};

export const useBrandInfo = () => {
  // @ts-expect-error
  const { brand, brandFromJWT } = useMFEContext();
  const brandLower = brand.toLowerCase() as keyof typeof brands;
  const brandExists = brandLower && brandLower in brands;
  const isDreamscape = ['vodien', 'crazydomains'].includes(brandLower);

  return {
    brandFromJWT,
    hostApplication: isDreamscape ? 'dreamscape' : 'accountmanager',
    isDreamscape,
    ...(brandExists ? brands[brandLower] : brands.default),
  };
};

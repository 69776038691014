import { PropsWithChildren } from 'react';

import { LoadingButton, LoadingButtonProps } from '@mui/lab';

const LoadingButtonWrapper = ({
  children = '',
  disabled,
  ...props
}: PropsWithChildren<LoadingButtonProps>) => {
  return (
    <LoadingButton
      variant="contained"
      aria-label="Loading Button"
      data-testid="loading-button"
      disabled={disabled}
      aria-disabled={disabled}
      {...props}
    >
      <span>{children}</span>
    </LoadingButton>
  );
};

export default LoadingButtonWrapper;

import { Dispatch, useEffect, useState } from 'react';

export type UseLocalStorageProps = [any, Dispatch<any>, (arg: string) => void];

export default function useLocalStorage(
  key: string,
  defaultValue: any,
  enabled: boolean = true,
) {
  const [value, setValue] = useState(() => {
    let currentValue;

    try {
      currentValue = JSON.parse(
        localStorage.getItem(key) || String(defaultValue),
      );
    } catch (error) {
      currentValue = defaultValue;
    }

    return currentValue;
  });

  const removeItem = (key: string) => localStorage.removeItem(key);

  useEffect(() => {
    if (enabled) {
      localStorage.setItem(key, JSON.stringify(value));
    }
    const hasLSItem = !!localStorage.getItem(key);
    if (!enabled && hasLSItem) {
      localStorage.removeItem(key);
    }
  }, [value, key, enabled]);

  return [value, setValue, removeItem] as UseLocalStorageProps;
}
